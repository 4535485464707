.App {
  text-align: center;
  height: 100vh;
  width: 100vw;
}
#overlay-preload {
  pointer-events: none;
  opacity: 1;
  height: 100vh;
  width: 100vw;
  background: black;
  z-index: 99999;
  position: absolute;
}
#overlay-preload.transparent {
  opacity: 0;
  transition: opacity 3s ease-out;
}

.map-container {
  height: 100%;
  width: 100%;
}
.clickable {
  cursor: pointer;
}

#place {
  position: absolute;
  top: 10px;
  z-index: 999;
  width: max-content;
  padding: 15px;
  background: #000000d1;
  color: white;
  /* border-radius: 5px; */
  font-weight: 700;
}

#place .address {
  font-size: 0.7rem;
  margin-bottom: -2px;
  color: #c84615;
}


@media (max-width:768px) {
  #place,#people {
    width:auto;
    border-radius: 0px;
  }
}

#people {
  position: absolute;
  z-index: 1;
  font-weight: 300;
  bottom: 10px;
  font-size: 0.9rem;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: auto;
  align-content: flex-end;
}

#people .data {
  flex: 0 0 auto;
  border-radius: 3px;
  margin: 7px;
  padding: 7px;
  background: #000000c7;
  color: white;
}
#people .link-message {
  position: absolute;
  bottom: 58px;
  background: black;
  color: #c84615;
  padding: 4px;
  border-radius: 5px;
}

#people
.data span, .data svg{
  vertical-align: middle;
}

#loading-text {
  color: white;
}

/* CENTERING */
.horizontally-centered {
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

.all-centered {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

iframe {
  border: 5px solid black;
  position: absolute;
  z-index: 1;
  bottom: 20px;
  right: 10px;
  padding: 10;
}